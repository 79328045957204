@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  /* Main Color Palette */
  --dark-blue-theme: #0A253D;
  --navy-blue-theme: #052067;
  --royal-blue-theme: #002DA0;
  --light-cyan-theme: #00c2c7;
  /* ----------------- */

  --gray-dark: #8492A6;
  --red-theme: #ff3737;
  --red-dark: #8B0000;
  --light-gray: #999999;
  --gray-theme: #E5E9F2;
  --yellow-theme: #FFEE00;
  --green-theme: #2ecc71;
  --green-dark-theme: #2a753e;

  --background: #EEEEEE;

  --danger-10: #DD695E;
  --danger-20: #D87067;
  --danger-30: #CE7F78;
  --danger-40: #C98680;
  --danger-50: #C48D88;
  --danger-60: #BF9490;
  --danger-70: #BA9B99;
  --danger-80: #B5A3A1;
  --danger-90: #B0AAA9;
  --danger-100: #ADADAD;
  --danger-light: #f69f9a;
  --danger-base: #FF3737;
  --danger-darker: #660000;
  --danger-contrast: #F6F8F9;

  --basic-white: #F6F8F9;
  --basic-black: #343536;
  --basic-blue: #8bd5eb;
  --basic-red: #EF5C54;
  --basic-green: #2ecc71;
  --basic-orange: #FFD074;
  --basic-pink: #FF747E;
  --basic-purple: #6969C1;
  --basic-primary: #0066FF;
  --basic-blue-dark: #2d5a95;
  --basic-blue-medium: #00a8d7;

  --info-dark: #2780ff;
  --info-base: #0076A4;
  --info-contrast: #F6F8F9;
  --info-background: #e0ecff;

  --background-hover: #F9FAFC;
  --background-base: #EEEEEE;
  --background-dark: #7B7B7B;
  --background-gray: #CCCCCC;

  --border-light: #F7F7F7;
  --border-base: #E5E9F2;
  --border-dark: #C0CCDA;
  --border-gray: #707070;

  --gray-base: #EEF2F5;
  --gray-dark: #8492A6;

  --dark-base: #273444;
  --dark-contrast: #8D94A7;
  --dark: #363B4B;

  --disabled-base: #EEEEEE;

  --warning-base: #FFD074;
  --warning-dark: #e67e22;
  --warning-contrast: #F6F8F9;
  --warning-new: #FF9130;

  --blue-gradient: linear-gradient(180deg, #0A1B24 0%, #084D8D 100%, #084A86 100%);
  --light-cyan-gradient:  linear-gradient(180deg, #0D0A0B 0%, #00c2c7 100%);
  --dark-blue-gradient: linear-gradient(180deg, #091C27 0%, #0A253D 100%);
  --red-gradient: linear-gradient(180deg, #870000 0%, #BE0000 56.25%, #F10000 100%);
  --orange-gradient:  linear-gradient(180deg, #8B0000 0%, #FF4500 100%);
  --green-gradient: linear-gradient(180deg, #147A0B 0%, #56B14E 100%, #56b14e00 100%);
}

html, body {
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
  background-color: #F2F2F2;
}

p, h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
  line-height: 100%;
  width: fit-content;
  height: fit-content;
}

button {
  cursor: pointer;
  border: none;
}

/* ===== Scrollbar CSS ===== */
/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

*::-webkit-scrollbar-button {
  display: none;
}

*::-webkit-scrollbar-track {
  background: #cccccc;
  border-radius: 8px;
}

*::-webkit-scrollbar-thumb {
  background-color: #0A253C;
  border-radius: 8px;
}

textarea:focus {
  outline:0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px transparent inset !important;
}

input:-webkit-autofill{
  -webkit-text-fill-color: #999999 !important;
}

*::-webkit-file-upload-button {
 cursor: pointer;
}

.Toastify__toast-body {
  white-space: pre-line;
}

.react-tooltip {
  z-index: 29;
}
